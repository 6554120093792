import { mapGetters } from 'vuex';
export const propertyGroupTypeMixin = {
    computed:{
        ...mapGetters({
            propertyGroupTypes: 'propertiesStore/propertyGroupTypes',
            filteredPropertyGroups: "propertiesStore/filteredPropertyGroups",
        })
    },
    methods: {
        getPropertyGroupType(id) {
            let groupType = this.propertyGroupTypes?.find(type => type.id == id);
            return groupType;
        },
        getPropertyGroupTypeName(id) {
            let groupType = this.getPropertyGroupType(id);
            return groupType?.name;
        },
        getFilteredPropertyGroupTypes() {
            let groupTypeIds = this.filteredPropertyGroups?.map(group => group.property_group_type_id) || [];
            return [ { id: null }, ...this.propertyGroupTypes?.filter(type => groupTypeIds.includes(type.id)) ];
        },
        getPropertyGroupTypeDisplayText(id) {
            let groupType = this.getPropertyGroupType(id);
            switch (groupType?.label || '') {
                case '':
                  return 'View All Groups'
                case 'custom':
                  return 'Custom Group'
                default:
                  return `Grouping by ${groupType?.name}`
            }
        }
    }
};